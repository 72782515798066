@import '../../css/variables.scss';

.events {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 100%;

  @media (max-aspect-ratio: 1/1.1) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .mapContainer {
    width: calc(100% - 400px);
    height: calc(100% - 20px);
    max-height: 500px;
    overflow: hidden;
    z-index: 1;
    border: 1px solid $main-color;
    position: relative;

    .mapLoader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #ffece2;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > div {
      z-index: 1;
    }

    .clusterMarker {
      background-color: $main-color;
      height: 30px;
      width: 30px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;

      transform: translate(-50%, -50%);
    }

    .popupMarkerOpenBtn {
      font-family: 'RobotoR';
      margin-top: 10px;
      cursor: pointer;
      background-color: $main-color;
      color: white;
      padding: 5px;
      border: none;
      border-radius: 10px;
      font-weight: 600;
      width: 80px;
      text-align: center;
    }

    @media (max-aspect-ratio: 1/1.1) {
      width: 100%;
      min-height: 50%;
    }
  }

  .eventsContainer {
    width: 400px;
    height: 100%;

    @media (max-aspect-ratio: 1/1.1) {
      width: 100%;
      min-height: 50%;
    }

    .eventsList {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $small-pad;
      width: 100%;
      height: 100%;
      padding-bottom: $small-pad;
      overflow-y: auto;

      .eventCard {
        position: relative;
        width: 98%;
        background-color: white;
        padding: $small-pad;
        margin-top: 5px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 1px 1px 2px 2px rgb(137 137 137 / 30%);
        cursor: pointer;

        &.empty {
          cursor: auto;

          .content {
            background-color: rgba(128, 128, 128, 0.5);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 20px;
            border-radius: 5px;
          }

          .eventTime {
            width: 150px;
            height: 16px;
            opacity: 0.5;
          }
        }

        &.inTime {
          .eventTime {
            color: white;
            font-weight: bold;
            background-color: rgb(77, 116, 77);
          }
        }

        &.selected {
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: $main-color;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 15px;
          width: 100%;
          gap: 5px;
        }

        .content {
          width: 100%;
          text-align: left;
          margin-top: 10px;
        }

        .paid {
          color: $main-color;
        }

        .privacy {
          color: $main-color;
        }

        .eventTime {
          font-size: 12px;
          color: white;
          background-color: $main-color;
          border-radius: 5px;
          padding: 0px 5px;
        }

        .categories {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          color: $main-color;
          flex: 1;
        }
      }
    }
  }
}
