@import '../../../css/variables.scss';

.event {
  height: 180px;

  background-color: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 2px 2px rgb(137 137 137 / 30%);
  cursor: pointer;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    min-height: 25px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $variant-color;
    padding: 0 10px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .cost {
      color: $main-color;
    }

    .privacy {
      color: $main-color;
    }

    .category {
      color: $main-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;
    }

    .controls {
      color: $main-color;
    }
  }

  .name {
    line-height: 1;
    padding: 5px;
    overflow: hidden;
  }

  .footer {
    line-height: 1;
    width: 100%;
    background-color: #fff7ee;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 2px 0px;
    color: $main-color;

    &.inTime {
      color: $green;
      font-weight: 600;
    }

    &.outOfTime {
      color: $red;

      & > span {
        text-decoration: line-through;
      }
    }

    .timeLabel {
      font-size: 12px;
      height: 12px;
    }
  }
}
