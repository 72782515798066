@import '../../css/variables.scss';

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;

  .animatedBlock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-bottom: 10px;
    gap: 5px;

    height: 50px;
    width: 300px;
    background-image: url('../../assets/imgs/big_logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .description {
    transition: opacity 0.3s;
    position: absolute;
    bottom: -10px;
    font-size: 1.3rem;
    color: $main-color;
  }

  .authBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    height: 70px;
    justify-content: space-between;
    transition: opacity 1s ease-in;
  }
}
