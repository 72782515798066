.AppWrapper {
  background-color: #fffefe;
}

.App {
  text-align: center;
  margin: auto;
  height: 100vh;

  color: #2d2d2d;

  .hello {
    color: rgb(0, 0, 0);
  }
}
