@import '../../css/variables.scss';

.filters {
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  background-color: white;
  z-index: 2;

  display: flex;
  flex-direction: column;

  .header {
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $main-color;

    div {
      flex: 1;
    }

    .title {
      text-align: center;
      color: $main-color;
      font-weight: 600;
      font-size: 22px;
    }

    .cancel {
      cursor: pointer;
      color: $main-color;
    }
  }

  .content {
    width: 1000px;
    max-width: 100%;
    margin: auto;

    height: calc(100% - 125px);
    padding: $medium-pad;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: $medium-pad;

    .filter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-bottom: $medium-pad;
      text-align: left;

      .title {
        font-weight: 600;
        margin-bottom: $medium-pad;
        .hint {
          font-weight: normal;
          font-size: 14px;
        }
      }

      border-bottom: 1px solid $main-color;
    }
  }

  .footer {
    height: 80px;
    border-top: 2px solid $main-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
