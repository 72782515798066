@import '../../css/variables.scss';

.menuBtn {
  cursor: pointer;
  color: $main-color;
}

.menuItem {
  color: $main-color !important;
}
