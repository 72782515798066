@import '../../css/variables.scss';

.profile {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  overflow-y: auto;

  .events {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid $main-color;

    .eventsList {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 10px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 5px;

      @media (max-width: 450px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }

      &.noEvents {
        grid-template-columns: auto;
      }

      .warning {
        margin: auto;
      }
    }
  }

  @media (max-width: 450px) {
    .events {
      .eventsList {
        margin-top: 5px;
      }
    }
  }
}
