@import '../../css/variables.scss';

.profileModal {
  width: 100dvw;
  height: 100dvh;
  overflow-y: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(253, 248, 245);
  position: relative;

  @media (max-height: 800px) {
    justify-content: flex-start;
  }

  .window {
    background-color: white;
    width: 500px;
    max-width: 95%;
    padding: $medium-pad;
    border-radius: $border-radius;
    box-shadow: 2px 2px 3px 0px #0000004a;
    display: flex;
    flex-direction: column;
    gap: $big-pad;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;

    .title {
      font-family: 'RobotoB';
      font-size: 1.2rem;
      color: $main-color;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $big-pad;
    }

    .birthPicker {
    }

    .female,
    .male {
      cursor: pointer;
      width: 25px;
      margin-left: 10px;
    }

    .sexText {
      width: 100px;
      margin-left: 10px;
    }

    .female {
      color: rgb(131, 131, 131);
      &.selected {
        color: rgb(231, 82, 107);
      }

      &:hover {
        color: rgb(231, 82, 107);
      }
    }

    .male {
      color: rgb(131, 131, 131);
      &.selected {
        color: rgb(57, 57, 228);
      }

      &:hover {
        color: rgb(57, 57, 228);
      }
    }

    .submitBtn {
      margin-top: 15px;
    }
  }
}
