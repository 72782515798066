@import '../../../css/variables.scss';

.avatar {
  width: inherit;
  height: inherit;
  background-color: #fff4ea;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 1px solid $main-color;
  background-size: contain;
  color: #838383;
  display: flex;
  align-items: center;
  justify-content: center;
}
