@import '../../css/variables.scss';

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.view {
  height: calc(100% - 125px);
  width: 1000px;
  max-width: 100%;
  margin: auto;
}

.header {
  width: 100%;
  height: 45px;
  border-bottom: 2px solid $main-color;

  .headerWrapper {
    height: 100%;
    width: 1000px;
    max-width: 100%;
    margin: auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 3;

    .logo {
      flex: 1;
      height: 35px;
      background-image: url('../../assets/imgs/big_logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
    }
  }
}

.footer {
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  z-index: 1;
  border-top: 2px solid $main-color;

  .btn {
    width: 150px;
    height: 100%;
    color: $highligth-color;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      width: 32px;
      height: 32px;
    }

    .title {
      margin-top: 10px;
      user-select: none;
    }

    &.active {
      color: $main-color;
    }
  }
}
