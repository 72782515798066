@import '../../css/variables.scss';

.listEvents {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .city {
    padding: $small-pad;
  }

  .events {
    flex: 1;
    overflow-y: auto;
    padding: $small-pad;
    gap: $small-pad;
    display: flex;
    flex-direction: column;
    align-items: center;

    .event {
      position: relative;
      width: 100%;
      background-color: white;
      border-radius: 5px;
      box-shadow: 1px 1px 2px 2px rgb(137 137 137 / 30%);

      .arrow {
        color: $main-color;
      }

      .shortinfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 60px;
        padding: $small-pad;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .timeInfo {
          position: absolute;
          top: 2px;
          left: 10px;
          font-weight: bold;
          font-size: 12px;
          color: grey;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;

          &.inTime {
            color: $green;
          }
          &.outOfTime {
            color: $red;
          }
        }

        .title {
          font-weight: bold;
          flex: 1;
          text-align: left;
          line-height: 1.1;
          padding-top: 10px;
        }

        .shortDetais {
          text-align: right;
          margin-right: 5px;
          margin-left: 5px;

          .time {
            .date {
              font-size: 14px;
              font-weight: bold;
            }

            .time {
              font-size: 16px;
            }

            &.inTime {
              color: $green;
            }
            &.outOfTime {
              color: $red;
              text-decoration: line-through;
            }
          }
        }
      }

      &.selected {
        .shortinfo {
          border-bottom: 1px solid $main-color;
        }

        .detailedInfo {
          display: flex;
        }
      }

      .detailedInfo {
        display: none;
        flex-direction: column;
        padding: 5px;
        width: 100%;

        .details {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 100%;

          .props {
            position: absolute;
            left: 5px;
            top: 5px;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            gap: 2px;

            .prop {
              border-radius: 5px;
              background-color: #f7f7f7;
              color: $main-color;
              padding: 0px 5px;
              height: 22px;

              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 5px;

              .title {
                color: black;
              }
            }

            .inTime {
              color: $green;
            }
            .outOfTime {
              color: $red;
            }
          }

          .cover {
            max-width: 100%;
            width: 400px;
            height: 150px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;
            position: relative;
          }

          .info {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            flex: 1;
            text-align: left;
            position: absolute;
            max-width: 50%;
            right: 8px;
            top: 5px;
            height: max-content;
            max-height: 145px;
            overflow-y: auto;
            background-color: #f7f7f7;
            padding: 5px;
            border-radius: 5px;
            word-wrap: break-word;
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        // padding-left: 15px;

        .openBtn {
          flex: 1;
          // margin-left: 20px;
        }
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
