@import '../../css/variables.scss';

.user {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  overflow-y: auto;

  .subscribeSection {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

    .subsribe {
      margin: 0 10px;
      width: 150px;

      .pending {
        font-size: 14px;
        line-height: 0.9;
        margin-bottom: 5px;
      }
    }
  }

  .eventsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    border-top: 1px solid $main-color;

    &.noEvents {
      grid-template-columns: auto;
    }

    .warning {
      margin: auto;
    }
  }

  @media (max-width: 450px) {
    .eventsList {
      margin-top: 5px;
    }
  }
}
