@import '../../css/variables.scss';

.infoPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $medium-pad;

  .followers,
  .subscribes {
    position: relative;
    user-select: none;
    cursor: pointer;
    font-weight: 600;
    width: 120px;

    .count {
    }

    .new {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: $main-color;
      color: white;
      border-radius: 50%;
      top: -32px;
      left: calc(50% - 15px);
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: $main-color;
    }
  }
  .mainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
    margin-right: 10px;
    width: 100px;

    .username {
      font-family: 'RobotoB';
      font-size: 1.2rem;
      width: 1000px;
      max-width: 95dvw;
      cursor: pointer;
      user-select: none;
    }

    .fio {
      font-size: 1.1rem;
      margin-top: 5px;
      width: 1000px;
      max-width: 95dvw;
    }

    .description {
      width: 1000px;
      max-width: 95dvw;
    }

    .avatarBlock {
      position: relative;
      .avatar {
        width: 100px;
        height: 100px;
      }

      .changeAvatarBtn {
        position: absolute;
        height: 25px;
        width: 25px;
        color: $main-color;
        cursor: pointer;
        right: 2px;
        bottom: 2px;
        border-radius: 50%;

        & > svg {
          position: absolute;
          z-index: 2;
          left: 0;
        }

        .plusBg {
          position: absolute;
          background-color: white;
          height: 20px;
          width: 20px;
          top: 2px;
          left: 2px;
          border-radius: 50%;
          z-index: 1;
        }
      }
    }
  }
}
