@import '../../css/variables.scss';

.events {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .tabs {
  }

  .content {
    height: calc(100% - 80px);
    width: 100%;
  }

  .filters {
    display: flex;
    flex-direction: row;
    gap: $medium-pad;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $main-color;
    padding: $small-pad;

    .filterBtn,
    .sortBtn {
      border-radius: $border-radius;
      height: 25px;
      font-size: 10px;
    }
  }
}
