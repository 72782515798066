@import '../../css/variables.scss';

.about {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 3px 0px #0000004a;
    padding: 10px 15px;
    margin-top: 20px;
    width: 90%;
    text-align: left;

    .title {
      color: $main-color;
      font-size: 24px;
    }

    .description {
      font-size: 18px;
    }
  }

  .linkName {
    display: inline-block;
    width: 90px;
  }
}
