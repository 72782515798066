@import '../../../css/variables.scss';

.loaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff3e660;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $main-color;

  pointer-events: all;
  z-index: 2;
}
