@import '../../css/variables.scss';

.event {
  padding: $small-pad;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .information {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    align-items: flex-start;
    font-size: 18px;
    position: relative;

    .bookmark {
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .remove {
      position: absolute;
      right: 5px;
      top: 5px;
      color: $main-color;
      cursor: pointer;
    }

    .shareBlock {
      margin: auto;
      margin-bottom: 20px;
    }

    .name {
      font-weight: 600;
      font-size: 18px;
      align-self: center;
      margin-bottom: 10px;
      line-height: 1.1;
    }

    .share {
      padding-bottom: 10px;
    }

    .creator {
      cursor: pointer;
      align-items: center;

      .label {
      }

      .user {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .userName {
          user-select: none;
          margin-left: 5px;
          color: $main-color;
          text-align: left;
          font-weight: 600;
        }

        &:hover {
          .userLabel {
            color: $main-color;
          }
        }
      }

      .avatar {
        width: 24px;
        height: 24px;
      }
    }

    .cover {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 5px;
      align-self: center;

      & > img {
        width: 100%;
        height: auto;
      }
    }

    .timeBlock {
      align-items: center;
      gap: 5px;
      width: 100%;
      padding: 10px;
      background: #f7f7f7;
      border-radius: 10px;
      margin-bottom: 10px;

      .icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        & > span {
          font-size: 12px;
        }
      }

      &.futureTime {
        .icon {
          color: grey;
        }
      }

      &.outOfTime {
        color: rgb(211 45 45);
      }

      &.inTime {
        color: rgb(77, 116, 77);
        font-weight: 600;
      }

      .time {
        display: flex;
        flex-direction: column;
        text-align: right;
        flex: 1;

        .label {
          font-size: 10px;
        }
      }
    }

    .props,
    .creator,
    .timeBlock,
    .description {
      width: 100%;
      padding: 10px;
      background: #f7f7f7;
      border-radius: 10px;
      margin-bottom: 10px;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .props {
      .icon {
        color: $main-color;
        margin-right: 10px;
      }
    }

    .description {
    }

    .categories {
      display: flex;
      flex-wrap: wrap;
      .category {
        margin-right: 20px;
        display: flex;
        .icon {
          width: 16px;
          color: $main-color;
          margin-left: 5px;
        }
      }
    }

    .ages {
      &.error {
        color: rgb(211 45 45);
      }
    }

    .address {
      width: 100%;
      margin-top: 10px;

      .title {
        background-color: $main-color;
        color: white;
        padding: $small-pad;
      }

      .map {
        width: 100%;
        height: 300px;
        position: relative;
        z-index: 1;
        border: 2px solid $main-color;

        .staticMapImage {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 100%;
        }

        .marker {
          width: 32px;
          height: 32px;
          position: absolute;
          top: calc(50% - 16px);
          left: calc(50% - 16px);
          background-position: center;
          background-repeat: no-repeat;
        }

        .navigateLink {
          display: block;
          position: absolute;
          cursor: pointer;
          left: 10px;
          bottom: 40px;
          color: white;
          height: 30px;
          width: 40px;
          padding-top: 4px;
          text-decoration: none;
          background-color: $main-color;
          border-radius: 12px;
          transition: background-color 0.1s ease-out;
          z-index: 2;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .green {
      color: rgb(67 177 67);
    }

    .privacy {
      justify-self: flex-end;
      font-style: italic;
      margin-top: 20px;
      font-weight: 100;
    }
  }

  .participants {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: $main-color;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .pcount {
      text-align: right;
    }
  }

  .subscribeSection {
    display: flex;
    flex-direction: column;

    margin-top: 20px;

    .description {
      margin-bottom: 5px;
      color: grey;
    }

    .subscribeBtn {
      justify-self: center;
      height: 50px;
    }
  }
}
