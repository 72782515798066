@import '../../css/variables.scss';

.verification {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .title {
    font-family: 'RobotoB';
    color: $main-color;
    font-size: 1.5rem;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    .logo {
      height: 35px;
      width: 35px;
      background-image: url('../../assets/imgs/logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .text {
      padding-top: 10px;
    }
  }

  .btn {
    width: 250px;
  }
}
