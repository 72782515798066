@import '../../css/variables.scss';

.createevent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  color: $main-color;
  font-weight: 600;

  .mapContainer {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(109, 109, 109, 0.493);
    align-items: center;
    justify-content: center;

    &.opened {
      display: flex;
    }

    .map {
      position: relative;
      overflow: hidden;
      width: 90%;
      height: 80%;
      background-color: grey;
      border: 2px solid $main-color;

      .center {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        z-index: 1000;
        color: $main-color;
        pointer-events: none;
      }

      .coords {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 190px;
        padding: 5px;
        background-color: #ff7d31de;
        color: white;
        border-bottom-left-radius: 5px;
      }

      .closeMap {
        position: absolute;
        z-index: 1000;
        bottom: 10px;
        left: calc(50% - 90px);
        width: 180px;
        height: 50px;
      }
    }
  }

  .window {
    width: 600px;
    max-width: 95%;
    padding: $small-pad;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-family: 'RobotoB';
    color: $main-color;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    .formRow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 25px;

      .ageLabel,
      .countLabel {
        min-width: 180px;
      }

      .label {
        margin-right: 10px;
        text-align: left;
      }
      & > input {
        padding: 2px 5px;
        width: 250px;

        &[aria-invalid='true'] {
          border-color: red;
        }
      }
      & > textarea {
        width: 250px;
        height: 100px;
        padding: 2px 5px;
        resize: none;
      }

      .mapBtn {
        margin-right: 10px;
        min-width: 185px;
      }

      .coords {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .coord {
          display: flex;
          flex-direction: row;
          align-items: center;

          & > span {
            width: 20px;
          }

          & > input {
            width: 160px;

            @media (max-width: 450px) {
              width: 120px;
            }
          }
        }
      }

      @media (max-width: 450px) {
        .ageLabel,
        .countLabel {
          min-width: unset;
          width: 100px;
        }
      }
    }

    .jcenter {
      justify-content: center;
    }

    .hiddenCheckbox {
      visibility: hidden;
      position: absolute;
    }
  }

  .lockContainer {
    width: 30px;
    height: 30px;
    color: $main-color;
    cursor: pointer;
    transform: translateY(-7px);

    &.unlocked {
      transform: translate(4px, -7px);
    }
  }

  .ageRangeBlock,
  .peopleRangeBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    @media (max-width: 450px) {
      .range {
        display: none;
      }

      .ageLimitInput,
      .peopleLimitInput {
        margin-right: 10px;
      }
    }

    .range {
      margin-left: 15px;
      margin-right: 15px;
      width: 200px;
    }

    .ageLimitInput,
    .peopleLimitInput {
      width: 55px;
    }
  }

  .timeDataPickers {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .createBtn {
    width: 150px;
    align-self: center;
  }
}
