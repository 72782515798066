@import '../../css/variables.scss';

.imageCropper {
  position: relative;
  width: 400px;
  height: 150px;
  border-radius: 10px;
  border: 2px dashed $main-color;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  .loadBtn {
    width: 100%;
    height: 100%;
    font-family: 'RobotoB';
    font-size: 24px;
    cursor: pointer;
    padding-top: 55px;
    color: $main-color;

    &.reload {
      color: transparent;
      user-select: none;
    }
  }

  .removeBtn {
    color: $main-color;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 150px;
  }
}
