@import '../../css//variables.scss';

.settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;

  .accordionTitle {
    color: $main-color;
    font-size: 18px;
    font-family: 'RobotoB';
  }

  .accordionDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .profileUrl {
    cursor: pointer;
  }
}
