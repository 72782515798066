@import '../../css/variables.scss';

.register,
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $big-pad;
  height: 100%;

  .logo {
    height: 35px;
    width: 35px;
    background-image: url('../../assets/imgs/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title {
    font-family: 'RobotoB';
    color: $main-color;
    font-size: 1.5rem;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .text {
      padding-top: 10px;
    }
  }

  .registerBtn {
    color: $second-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
