html {
  font-family: 'RobotoR';
  line-height: normal;
}

#__range-picker-container .calendar {
  top: 30% !important;
  left: 35% !important;
  z-index: 10;
}

@media (max-width: 800px) {
  #__range-picker-container .calendar {
    left: calc(50% - 140px) !important;
  }
}

#loader {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 3;
}
