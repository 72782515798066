@import '../../../css/variables.scss';

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.4);
  box-shadow: 2px 1px black;
  z-index: 3;
  overflow-y: hidden;

  transition: opacity 1s ease-in;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 95%;
  height: 600px;
  max-height: 70%;
  background-color: white;
  box-shadow: 3px 3px 4px 0px #00000069;
  border-radius: 10px;
  padding: 5px;

  .title {
    font-size: 18px;
    font-weight: 600;
    height: 30px;
  }

  .usersList {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    height: calc(100% - 35px);
    overflow-y: auto;

    .user {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 5px;
      width: 90%;
      height: 40px;
      min-height: 40px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 13%);
      cursor: pointer;

      .name {
        margin-left: 10px;
        text-align: left;
        flex: 1;
        line-height: 1;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .accept {
        height: 80%;
        width: 80px;
        background-color: #398b39;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5px;
      }

      .cancel {
        margin-left: 5px;
        color: $main-color;
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
