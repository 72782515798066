@import '../../../css/variables.scss';

.share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;

  .btn {
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .qrCodeBtn {
    color: $main-color;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.qrCodeOverlay {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
