// $main-color: #2a4480;
// $second-color: #a5bae9;
// $variant-color: #009999;
// $highligth-color: #6c8cd5;

$main-color: #ff7d31;
$second-color: #ffaa79;
$variant-color: #ffe1c3;
$highligth-color: #f5c595;

$green: #4d744d;
$red: #a72323;

$small-pad: 10px;
$medium-pad: 15px;
$big-pad: 20px;

$border-radius: 10px;
